import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";
import "../css/home.css";
import ReCAPTCHA from "react-google-recaptcha";

const GetQuoteLanding = () => {
  const featuresData = [
    {
      key: "precisionControllers",
      heading: "Precision Controllers",
      description:
        "Controllers in an e-bike employs sensors and algorithms to adjust power delivery seamlessly and its intelligent system enhances performance and makes the e-bike more intuitive and user-friendly.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png",
    },
    {
      key: "highPerformanceChargers",
      heading: "High Performance Chargers",
      description:
        "The chargers come in a range of customisable options that cater to preferences, based on global compatibility, portability or extended power flexibility.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png",
    },
    {
      key: "multifunctionalDisplays",
      heading: "Multifunctional Displays",
      description:
        "These LCD displays offer real-time data like speed and battery which empowers riders to monitor and adjust their riding modes.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png",
    },
    {
      key: "longLastingBatteries",
      heading: "Long Lasting Batteries",
      description:
        "This electric system stores energy and powers the electric motor. This technology has been designed to ensure consistent performance, longer battery life, and optimal power delivery, enhancing the overall riding experience.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png",
    },
    {
      key: "efficientMotors",
      heading: "Efficient Motors",
      description:
        "Our versatile and innovative motors have been designed to offer reliable and accurate performance, ensuring optimal utility in various applications.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("precisionControllers");
  const [capVal, setCapVal] = useState("");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const handlePrevClick = () => {
    const currentIndex = featuresData.findIndex(
      (feature) => feature.key === activeFeature1
    );
    const prevIndex =
      (currentIndex - 1 + featuresData.length) % featuresData.length;
    setActiveFeature1(featuresData[prevIndex].key);
  };

  const handleNextClick = () => {
    const currentIndex = featuresData.findIndex(
      (feature) => feature.key === activeFeature1
    );
    const nextIndex = (currentIndex + 1) % featuresData.length;
    setActiveFeature1(featuresData[nextIndex].key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const response = await fetch("/api/verify-captcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ capVal }),
    });

    const dataCaptcha = await response.json();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      enquiry,
    };
    if (dataCaptcha) {
      const { data } = await axios.post(
        "/api/form/request-a-quote",
        reqBody,
        config
      );
      if (data) {
        navigate("/details-submitted");
      }
    } else {
      alert("CAPTCHA Verification Failed!");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta
        title={
          "Dynem - Top Ebike Manufacturer & Wholesale Electric Bike Supplier Worldwide"
        }
        description={
          "Dynem is an E-bike manufacturer and wholesale supplier based in India. With expertise in developing and producing all kinds of electric bikes and bike parts, Dynem supplies E-bikes in Europe, North America and other parts of the world."
        }
        cannonicalTag={"https://www.dynem.in/"}
      />
      {/* desktop navbar */}
      <Navbar />
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div id="home-page-hero-slider" className="home-main-slider mb-5">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
            data-interval="1000"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                className="active rounded-circle"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                className="rounded-circle"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                className="rounded-circle"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                className="rounded-circle"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/mobile/banners/Banner+1+mobile.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+1+Desktop.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+1+Desktop.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="w-100 container bigger-screen">
                  <div className="d-none d-lg-block get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-4"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "2.5em",
                        }}
                      >
                        Your Partner in Premium<br></br>
                        <span style={{ color: "#ff0000", fontSize: "1.3em" }}>
                          E-Bike Manufacturing
                        </span>
                      </h1>
                      <h2 style={{ fontSize: "1.5em" }} className="mb-4">
                        South Asia's Largest Manufacturing Facility
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-4 py-2.5" />
                    </div>
                  </div>
                </div>
                <div className="w-100 container tablet-view">
                  <div className="get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-3"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "1.3em",
                        }}
                      >
                        Your Partner in Premium<br></br>{" "}
                        <span style={{ color: "#ff0000", fontSize: "1em" }}>
                          E-Bike Manufacturing
                        </span>
                      </h1>
                      <h2 style={{ fontSize: "1em" }} className="mb-3">
                        South Asia's Largest Manufacturing Facility
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-3 py-2" />
                    </div>
                  </div>
                </div>
                <div
                  className="d-lg-none get-quote-home-banner position-absolute w-100 smaller-screen"
                  style={{ gap: "1em" }}
                >
                  <div className="">
                    <h1
                      className="mb-3"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "1.5em",
                      }}
                    >
                      Your Partner in Premium<br></br>
                      <span style={{ color: "#ff0000", fontSize: "1.3em" }}>
                        E-Bike Manufacturing
                      </span>
                    </h1>
                    <h2
                      style={{
                        fontSize: "1.2em",
                      }}
                      className="mb-3"
                    >
                      South Asia's Largest<br></br>Manufacturing Facility
                    </h2>
                  </div>
                  <ModalMobile className="d-lg-none primary-button fw-bold px-3 py-2" />
                </div>
              </div>
              <div className="carousel-item position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/mobile/banners/Banner+2+mobile.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+2+desktop.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+2+desktop.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="w-100 container bigger-screen">
                  <div className="d-none d-lg-block get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-4"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "3em",
                        }}
                      >
                        State of the art<br></br>Manufacturing
                      </h1>
                      <h2 style={{ fontSize: "1.5em" }} className="mb-4">
                        Production Capacity of 500,000+ Units Annually
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-4 py-2.5" />
                    </div>
                  </div>
                </div>
                <div className="w-100 container tablet-view">
                  <div className="get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-3"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "1.3em",
                        }}
                      >
                        State of the art<br></br>Manufacturing
                      </h1>
                      <h2 style={{ fontSize: "1em" }} className="mb-3">
                        Production Capacity of 500,000+ Units Annually
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-3 py-2" />
                    </div>
                  </div>
                </div>
                <div
                  className="d-lg-none get-quote-home-banner position-absolute w-100 smaller-screen"
                  style={{ gap: "1em" }}
                >
                  <div className="text-light">
                    <h1
                      className="mb-3"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "1.7em",
                      }}
                    >
                      State of the art<br></br>Manufacturing
                    </h1>
                    <h2
                      style={{
                        fontSize: "1.2em",
                      }}
                      className="mb-3"
                    >
                      Production Capacity of 500,000+<br></br>Units Annually
                    </h2>
                  </div>
                  <ModalMobile className="d-lg-none primary-button fw-bold px-3 py-2" />
                </div>
              </div>
              <div className="carousel-item position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/mobile/banners/Banner+3+mobile.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+3+desktop.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+3+desktop.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="w-100 container bigger-screen">
                  <div className="d-none d-lg-block get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-4"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "3em",
                        }}
                      >
                        Legal Anti Dumping and<br></br>Tariff Solution
                      </h1>
                      <h2 style={{ fontSize: "1.5em" }} className="mb-4">
                        Complete Governance with Maximum Savings
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-4 py-2.5" />
                    </div>
                  </div>
                </div>
                <div className="w-100 container tablet-view">
                  <div className="get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-3"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "1.3em",
                        }}
                      >
                        Legal Anti Dumping and<br></br>Tariff Solution
                      </h1>
                      <h2 style={{ fontSize: "1em" }} className="mb-3">
                        Complete Governance with Maximum Savings
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-3 py-2" />
                    </div>
                  </div>
                </div>
                <div
                  className="d-lg-none get-quote-home-banner position-absolute w-100 smaller-screen"
                  style={{ gap: "1em" }}
                >
                  <div className="text-light">
                    <h1
                      className="mb-3"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "1.7em",
                      }}
                    >
                      Legal Anti Dumping and<br></br>Tariff Solution
                    </h1>
                    <h2
                      style={{
                        fontSize: "1.2em",
                      }}
                      className="mb-3"
                    >
                      Rigorously Tested to be<br></br>World Class in Quality
                    </h2>
                  </div>
                  <ModalMobile className="d-lg-none primary-button fw-bold px-3 py-2" />
                </div>
              </div>
              <div className="carousel-item position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/mobile/banners/Banner+4++mobile.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+4+desktop.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+4+desktop.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="w-100 container bigger-screen">
                  <div className="d-none d-lg-block get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-4"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "3em",
                        }}
                      >
                        Carefully Crafted<br></br>Components
                      </h1>
                      <h2 style={{ fontSize: "1.5em" }} className="mb-4">
                        Rigorously Tested to be World Class in Quality
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-4 py-2.5" />
                    </div>
                  </div>
                </div>
                <div className="w-100 container tablet-view">
                  <div className="get-quote-home-banner position-absolute">
                    <div className="text-light">
                      <h1
                        className="mb-3"
                        style={{
                          fontFamily: "Metropolis-Bold",
                          fontSize: "1.3em",
                        }}
                      >
                        Carefully Crafted<br></br>Components
                      </h1>
                      <h2 style={{ fontSize: "1em" }} className="mb-3">
                        Rigorously Tested to be World Class in Quality
                      </h2>
                    </div>
                    <div className="d-flex" style={{ gap: "1.5em" }}>
                      <Modal className="primary-button fw-bold px-3 py-2" />
                    </div>
                  </div>
                </div>
                <div
                  className="d-lg-none get-quote-home-banner position-absolute w-100 smaller-screen"
                  style={{ gap: "1em" }}
                >
                  <div className="text-light">
                    <h1
                      className="mb-3"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "1.7em",
                      }}
                    >
                      Carefully Crafted<br></br>Components
                    </h1>
                    <h2
                      style={{
                        fontSize: "1.2em",
                      }}
                      className="mb-3"
                    >
                      Rigorously Tested to be<br></br>World Class in Quality
                    </h2>
                  </div>
                  <ModalMobile className="d-lg-none primary-button fw-bold px-3 py-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* world class products */}
        <div className="px-2 px-lg-0">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">
              We Manufacture World Class E-Bikes and Components
            </h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="">
                  <Link
                    to={"/e-bikes"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/type+of+products/type1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Link>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="">
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/type+of+products/type2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Link>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="">
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/type+of+products/type3.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Link>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="">
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/type+of+products/type4.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Link>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal className="primary-button fw-bold px-4 py-2  d-lg-block d-none" />
            <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 " />
          </div>
        </div>
        {/* company video */}
        {/* <div className="pt-5">
          <ReactPlayer
            width="100%"
            url="https://youtu.be/v_v6qV2qzE0?si=OJmGnZ32M59BDpGx"
          />
        </div> */}
        {/* company logos */}
        <div className="container my-5 py-lg-5 px-2 px-lg-0">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* range of e-bikes */}
        <div className="">
          <div className="position-relative">
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/banners/Mid+banner+ebike++mobile.jpg"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/banners/Min+banner+ebike+Desktop.jpg"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/banners/Min+banner+ebike+Desktop.jpg"
                alt="hero-banner"
                className="d-block w-100"
                style={{ opacity: "0.8" }}
              />
            </picture>
            <div className="container bigger-screen">
              <div
                className="d-none d-lg-block get-quote-home-banner position-absolute"
                style={{ zIndex: 3, top: "12%" }}
              >
                <div className="text-light">
                  <h2
                    style={{ fontSize: "2.5em", fontFamily: "Metropolis-Bold" }}
                    className="mb-0"
                  >
                    Scale your{" "}
                    <span style={{ color: "#ff0000" }}>E-bike business</span>
                    <br></br>effortlessly
                  </h2>
                </div>
              </div>
            </div>
            <div className="container tablet-view">
              <div className="get-quote-home-banner position-absolute">
                <div className="text-light">
                  <h2
                    style={{ fontSize: "1.5em", fontFamily: "Metropolis-Bold" }}
                    className="mb-3"
                  >
                    Scale your{" "}
                    <span style={{ color: "#ff0000" }}>E-bike business</span>
                    <br></br>effortlessly
                  </h2>
                </div>
              </div>
            </div>
            <div className="d-lg-none  smaller-screen">
              <div className="get-quote-home-banner position-absolute">
                <h2
                  style={{
                    fontSize: "1.5em",
                    fontFamily: "Metropolis-Bold",
                  }}
                  className="mb-3"
                >
                  Scale your<br></br>
                  <span style={{ color: "#ff0000" }}>E-bike business</span>
                  <br></br>
                  effortlessly
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 pt-lg-5 py-4 px-2 px-lg-0">
          <h2 className=" container poppins-medium mb-4 mb-lg-0">
            Explore Our Electric Fleet
          </h2>
          <div className="range-ebikes container pb-4">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "2em",
                padding: "1em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 pb-2">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Mountain+bike.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6 p-lg-3">
                    <h4 className="poppins-medium">Mountain E-Bikes</h4>
                    <p className="ecycle-range-car-content">
                      We're not just a mountain E-bike manufacturer; we're
                      trailblazers in off-road adventure. Explore a range of
                      e-bikes meticulously crafted for rugged terrains and
                      thrilling rides.
                    </p>
                    <div
                      className="col col-md-3 text-center py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/mountain"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100 pb-2">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Foldable+Fat+tyre.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6 p-lg-3">
                    <h4 className="poppins-medium">
                      Folding & Fat Tyre E-Bikes
                    </h4>
                    <p className="ecycle-range-car-content">
                      As a top-tier folding eBike manufacturer, DYNEM brings you
                      the ultimate blend of convenience and style. Our folding
                      e-bikes are perfect for city explorers and jet-setters.
                    </p>
                    <div
                      className="col col-md-3 text-center py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/folding-fat-tyre"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100 pb-2">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/City+Bike.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6 p-lg-3">
                    <h4 className="poppins-medium">City E-Bikes</h4>
                    <p className="ecycle-range-car-content">
                      As a premier city bike manufacturer, DYNEM redefines urban
                      commuting with sleek, efficient, and stylish rides. Our
                      city bikes blend cutting-edge technology with modern
                      design, ensuring you glide through traffic with ease.
                    </p>
                    <div
                      className="col col-md-3 text-center py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/city"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100 pb-2">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Delivery+bike.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6 p-lg-3">
                    <h4 className="poppins-medium">
                      <h4 className="poppins-medium">Delivery E-Bikes</h4>
                    </h4>
                    <p className="ecycle-range-car-content">
                      As a leading delivery bike manufacturer, DYNEM delivers
                      top-tier performance and reliability for every delivery.
                      Our e-bikes are designed for the demands of urban
                      logistics, powerful motors, and ample cargo space.
                    </p>
                    <div
                      className="col col-md-3 text-center py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/delivery"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal className="primary-button fw-bold px-4 py-2  d-lg-block d-none" />
            <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 " />
          </div>
        </div>
        {/* range of dts */}
        <div>
          <div className="position-relative">
            <picture className="">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/banners/Mid+banner+mobile.jpg"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/banners/Mid+banner+Desktop.jpg"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/banners/Mid+banner+Desktop.jpg"
                alt="hero-banner"
                className="d-block w-100"
              />
            </picture>
            <div className="px-2 px-lg-0">
              <div className="container mt-5 py-lg-5">
                <Splide
                  className="mb-2 mb-lg-0 p-0 align-items-center"
                  options={{
                    arrows: false,
                    drag: false,
                    type: "slide",
                    pagination: false,
                    autoplay: false,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    pauseOnHover: true,
                    resetProgress: false,
                    perPage: 4,
                    speed: 1000,
                    interval: 3000,
                    breakpoints: {
                      700: {
                        drag: true,
                        perPage: 1.3,
                        arrows: false,
                        perMove: 1,
                        gap: "1em",
                        padding: "0em",
                        pauseOnHover: false,
                        resetProgress: false,
                        focus: 0,
                        start: 0,
                        speed: 1000,
                        interval: 3000,
                      },
                    },
                  }}
                  muted={true}
                >
                  <SplideSlide className="h-100">
                    <div className="">
                      <Link
                        to={"/e-bikes"}
                        className="text-decoration-none text-dark"
                      >
                        <div>
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/usp/usp1.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Link>
                    </div>
                  </SplideSlide>
                  <SplideSlide className="h-100">
                    <div className="">
                      <Link
                        to={"/components"}
                        className="text-decoration-none text-dark"
                      >
                        <div>
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/usp/usp2.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Link>
                    </div>
                  </SplideSlide>
                  <SplideSlide className="h-100">
                    <div className="">
                      <Link
                        to={"/components"}
                        className="text-decoration-none text-dark"
                      >
                        <div>
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/usp/usp3.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Link>
                    </div>
                  </SplideSlide>
                  <SplideSlide className="h-100">
                    <div className="">
                      <Link
                        to={"/components"}
                        className="text-decoration-none text-dark"
                      >
                        <div>
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/usp/usp4.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Link>
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
            <div className="container">
              <div className="position-absolute get-quote-home-banner">
                <div className="d-none d-lg-block bigger-screen">
                  <div className="text-light">
                    <h1
                      className="mb-4"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "3.5em",
                      }}
                    >
                      World's Leading<br></br>E-Bike Manufacturer
                    </h1>
                    <h3 style={{ fontSize: "1.7em" }} className="mb-5">
                      Largest Exporter of E-Bikes in India
                    </h3>
                  </div>
                  <div className="d-flex" style={{ gap: "1.5em" }}>
                    <Modal className="primary-button fw-bold px-3 py-2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="position-absolute get-quote-home-banner">
                <div className="tablet-view">
                  <div className="text-light">
                    <h1
                      className="mb-3"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "2em",
                      }}
                    >
                      World's Leading<br></br>E-Bike Manufacturer
                    </h1>
                    <h3 style={{ fontSize: "1.3em" }} className="mb-4">
                      Largest Exporter of E-Bikes in India
                    </h3>
                  </div>
                  <div className="d-flex" style={{ gap: "1.5em" }}>
                    <Modal className="primary-button fw-bold px-3 py-2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="position-absolute get-quote-home-banner">
                <div className="d-lg-none smaller-screen">
                  <div className="text-light">
                    <h1
                      className="mb-3"
                      style={{
                        fontFamily: "Metropolis-Bold",
                        fontSize: "2em",
                      }}
                    >
                      World's Leading<br></br>E-Bike Manufacturer
                    </h1>
                    <h3
                      style={{
                        fontSize: "1.2em",
                      }}
                      className="mb-4"
                    >
                      Largest Exporter of E-Bikes in India
                    </h3>
                  </div>
                  <ModalMobile className="d-lg-none primary-button fw-bold px-3 py-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 pt-4 pt-lg-5 pb-lg-0 py-4">
          <div className="px-2 px-lg-0">
            <h2 className="poppins-medium mb-4 mb-lg-5">
              Components that Power Every Mile
            </h2>
            <div className="d-none pt-4">
              <Splide
                className="mb-5 p-0 align-items-center"
                options={{
                  drag: true,
                  perPage: 1.2,
                  arrows: false,
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  interval: 4000,
                  gap: "1em",
                  padding: "0em",
                  pauseOnHover: false,
                  resetProgress: false,
                  focus: 0,
                  start: 0,
                  speed: 500,
                }}
              >
                {featuresData.slice(0, 4).map((feature) => (
                  <SplideSlide className="feature-btn-width">
                    <button
                      key={feature}
                      className={
                        feature.key === activeFeature1
                          ? "em-btn-1 rounded-0"
                          : "em-btn-2 rounded-0"
                      }
                      onClick={() => handleFeature1Click(feature)}
                    >
                      {feature.heading}
                    </button>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
            <div className="mb-5 d-none d-lg-block">
              <div
                className="d-flex justify-content-center mb-3"
                style={{ gap: "1em" }}
              >
                {featuresData.map((feature) => (
                  <button
                    key={feature}
                    className={
                      feature.key === activeFeature1
                        ? "em-btn-1 rounded-0"
                        : "em-btn-2 rounded-0"
                    }
                    onClick={() => handleFeature1Click(feature)}
                  >
                    {feature.heading}
                  </button>
                ))}
              </div>
            </div>
            <div className="d-none d-lg-block my-5 position-relative">
              <div
                className="d-flex justify-content-between align-items-center my-4 position-absolute w-100"
                style={{ bottom: "50%" }}
              >
                <div onClick={handlePrevClick} style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="20"
                    height="20"
                    focusable="false"
                    fill="#ff0000"
                    style={{ transform: "scaleX(-1)" }}
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </div>
                <div onClick={handleNextClick} style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="20"
                    height="20"
                    focusable="false"
                    fill="#ff0000"
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center ps-5">
                <div className="feature-text w-100 ps-4">
                  <h1>{activeFeatureObj1.heading}</h1>{" "}
                  <p>{activeFeatureObj1.description}</p>
                </div>
                <div className="w-100">
                  <img
                    src={activeFeatureObj1.image}
                    alt="amigo-feature"
                    className="img-fluid"
                  ></img>
                </div>
              </div>
            </div>
            <div className="row mt-5 d-lg-none position-relative">
              <div
                className="d-flex justify-content-between align-items-center my-4 position-absolute w-100"
                style={{ bottom: "30%" }}
              >
                <div onClick={handlePrevClick} style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="20"
                    height="20"
                    focusable="false"
                    fill="#ff0000"
                    style={{ transform: "scaleX(-1)" }}
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </div>
                <div onClick={handleNextClick} style={{ cursor: "pointer" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    width="20"
                    height="20"
                    focusable="false"
                    fill="#ff0000"
                  >
                    <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
                  </svg>
                </div>
              </div>
              <div className="col-12 col-lg-6 feature-text">
                <h1>{activeFeatureObj1.heading}</h1>{" "}
                <p>{activeFeatureObj1.description}</p>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex d-lg-block justify-content-center">
                  <img
                    src={activeFeatureObj1.image}
                    alt="amigo-feature"
                    className="img-fluid mx-auto"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal className="primary-button fw-bold px-4 py-2  d-lg-block d-none" />
            <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 " />
          </div>
        </div>
        {/* amigo */}
        <div className=" mt-5 pt-lg-5">
          <div className="px-2 px-lg-0">
            <h2 className="poppins-medium mb-3 mb-lg-5 container ">
              Amigo - one app | limitless possibilities
            </h2>
          </div>

          <div className="pb-4">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                start: 0,
                drag: true,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                focus: "center",
                perPage: 1.5,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100  pb-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_1.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_2.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_3.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_4.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_5.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_6.png"
                  className="w-100"
                ></img>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5 pb-4">
          <Modal className="primary-button fw-bold px-4 py-2  d-lg-block d-none" />
          <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 " />
        </div>
        {/* testimonials */}
        <div className="mt-5 pt-lg-5 py-4 testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">
            Testimonials
          </h2>
          <div className="px-3">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                start: 0,
                drag: true,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "2.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                focus: "center",
                perPage: 1.5,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0.8em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100  pb-2">
                <div
                  className="d-flex w-100"
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial-1.jpg"
                      className="img-fluid"
                    ></img>
                  </div>
                  <div className="col-8 col-md-8 p-lg-4 p-3">
                    <p className="mb-2">Carolina Spike</p>
                    <p className="mb-0 testimonial-text">
                      Working with Dynem has been nothing short of excellent.
                      The transition from China to India was incredibly smooth,
                      and the cost savings we realized were significant.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <div
                  className="d-flex w-100"
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial-2.jpg"
                      className="img-fluid"
                    ></img>
                  </div>
                  <div className="col-8 col-md-8 p-lg-4 p-3">
                    <p className="mb-2">Jake Severisson</p>
                    <p className="mb-0 testimonial-text">
                      Switching to Dynem was a game-changer for us! The entire
                      process, from sourcing to delivery, was seamless. Highly
                      recommend!
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <div
                  className="d-flex w-100"
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial-3.jpg"
                      className="img-fluid"
                    ></img>
                  </div>
                  <div className="col-8 col-md-8 p-lg-4 p-3">
                    <p className="mb-2">Sachit Jain</p>
                    <p className="mb-0 testimonial-text">
                      Dynem made our shift to Indian manufacturing effortless.
                      Their attention to detail and commitment to quality were
                      impressive, and the cost benefits were immediate.
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100  pb-2">
                <div
                  className="d-flex w-100"
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <div className="">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial-4.jpg"
                      className="img-fluid"
                    ></img>
                  </div>
                  <div className="col-8 col-md-8 p-lg-4 p-3">
                    <p className="mb-2">Kevin Spearson</p>
                    <p className="mb-0 testimonial-text">
                      One of the best e-bike manufacturer in the market! Have
                      consistently gotten e-bikes customized to my liking and
                      they never disappoint, 11/10 recommended!
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <div className="px-2 px-lg-0">
              <h2 className="poppins-medium mb-3 mb-lg-5">In the news</h2>
              <Splide
                className="mb-2 mb-lg-0 p-0 align-items-center"
                options={{
                  arrows: false,
                  drag: false,
                  type: "slide",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  pauseOnHover: true,
                  resetProgress: false,
                  perPage: 4,
                  speed: 1000,
                  interval: 3000,
                  breakpoints: {
                    700: {
                      drag: true,
                      perPage: 1.3,
                      arrows: false,
                      perMove: 1,
                      gap: "1em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      focus: 0,
                      start: 0,
                      speed: 1000,
                      interval: 3000,
                    },
                  },
                }}
                muted={true}
              >
                <SplideSlide className="h-100">
                  <div className="p-3 " style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                      className="img-fluid mt-2"
                    ></img>
                    <p className="my-3 news-heading poppins-medium">
                      DYNEM begins construction of World's Biggest Integrated
                      Electric Cycle Gigafactory.....
                    </p>
                    <small>May 09, 2024</small>
                    <div
                      className="w-100 text-center  py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <a
                        className="text-light text-decoration-none"
                        href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 " style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                      className="img-fluid mt-2"
                    ></img>
                    <p className="my-3 news-heading poppins-medium">
                      India’s DYNEM to Establish World’s Largest Integrated
                      E-Bike Gigafactory
                    </p>
                    <small>May 09, 2024</small>
                    <div
                      className="w-100 text-center  py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <a
                        className="text-light text-decoration-none"
                        href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 " style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                      className="img-fluid mt-2"
                    ></img>
                    <p className="my-3 news-heading poppins-medium">
                      DYNEM Breaks Ground on World’s Largest Integrated Electric
                      Cycle Gigafactory ...
                    </p>
                    <small>May 09, 2024</small>
                    <div
                      className="w-100 text-center  py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <a
                        className="text-light text-decoration-none"
                        href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide className="h-100">
                  <div className="p-3 " style={{ backgroundColor: "white" }}>
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                      className="img-fluid mt-2"
                    ></img>
                    <p className="my-3 news-heading poppins-medium">
                      DYNEM Strengthens India’s Manufacturing Prowess: World’s
                      Biggest .....
                    </p>
                    <small>May 09, 2024</small>
                    <div
                      className="w-100 text-center  py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <a
                        className="text-light text-decoration-none"
                        href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing px-2 px-lg-0">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">
                Frequently asked questions
              </h2>
              <p className="mb-lg-5">
                Got questions? We’ve already got answers. It’s like we can hear
                you thinking.
              </p>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What makes DYNEM different from other e-bike manufacturers?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM stands out for its cutting-edge innovation,
                      eco-friendly practices, and extensive customization
                      options. Our state-of-the-art gigafactory, global
                      compliance, and expert team ensure top-notch quality and
                      seamless support from production to after-sales, leaving
                      you with nothing but peace of mind.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Can I customise my e-bike with DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Absolutely! DYNEM offers extensive customization options,
                      allowing you to tailor your e-bike to your specific style
                      and needs. Our OEM service lets you customise branding,
                      specifications, and features to match your preferences.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How many e-bikes does DYNEM produce annually?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM has the capacity to produce and customise 500,000
                      e-bikes annually. Our efficient production processes and
                      world-class infrastructure enable us to meet growing
                      global demand.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    What are the benefits of DYNEM's anti-dumping solutions?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM's legal anti-dumping solutions allow importers to
                      save up to 87% in taxes. Our products comply with global
                      regulations, ensuring a level playing field without any
                      anti-dumping taxes. This makes DYNEM a cost-effective and
                      compliant choice for importers worldwide.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    How does DYNEM support sustainability?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM is committed to sustainability through eco-friendly
                      practices and innovative technology. Our e-bikes are
                      designed to reduce carbon footprints and promote a greener
                      future, aligning with our vision of sustainable mobility.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    What kind of after-sales support does DYNEM provide?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM prioritises customer satisfaction with dedicated
                      after-sales support. From the initial purchase to
                      long-term maintenance, our team is committed to providing
                      seamless and reliable assistance, ensuring a smooth and
                      satisfying experience for our customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-none text-center pt-5">
          <ModalMobile className="d-lg-none primary-button fw-bold px-3 py-2" />
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal className="primary-button fw-bold px-4 py-2  d-lg-block d-none" />
        </div>
        {/* contact us */}
        <div className="mt-5 py-lg-5 py-4" style={{ backgroundColor: "black" }}>
          <div className="container">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                        className="w-75 p-3 "
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                        className="w-100 p-3 "
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3 "
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3 "
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">
                  Let’s Connect
                </h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-100">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Name*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Contact No*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="US"
                        className="form-control w-100 rounded-0"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Work Email*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-100">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organisation*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control rounded-0"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Post Your Enquiry*
                    </label>
                    <textarea
                      class="form-control rounded-0"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      required
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="mb-3 d-flex justify-content-center">
                    <ReCAPTCHA
                      sitekey="6LftKugqAAAAAP-72NIok7YyszCs5TTba5QBtgBS"
                      onChange={(val) => setCapVal(val)}
                    ></ReCAPTCHA>
                  </div>
                  <button
                    disabled={!capVal}
                    type="submit"
                    class="btn btn-light w-100 poppins-medium rounded-0"
                  >
                    Get Quote
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5 px-2 px-lg-0">
              <h2 className="poppins-medium mb-4 text-light">Let’s Connect</h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Name*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Contact No*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="US"
                      className="form-control w-100 rounded-0"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Work Email*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organisation*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control rounded-0"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Post Your Enquiry*
                  </label>
                  <textarea
                    class="form-control rounded-0"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="mb-3 d-flex justify-content-center">
                  <ReCAPTCHA
                    sitekey="6LftKugqAAAAAP-72NIok7YyszCs5TTba5QBtgBS"
                    onChange={(val) => setCapVal(val)}
                  ></ReCAPTCHA>
                </div>
                <button
                  type="submit"
                  disabled={!capVal}
                  class="btn btn-light w-100 poppins-medium rounded-0"
                >
                  Get Quote
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none px-2 px-lg-0">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                    className="w-75 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GetQuoteLanding;
