import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/modal.css";
import PhoneInput from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";

const Modal = (props) => {
  const [capVal, setCapVal] = useState("");

  const { className } = props;
  const { customBikeData } = props;
  const { language } = props;
  const translations = {
    french: {
      code: "FR",
      requestQuote: "Demander un devis",
      name: "Nom et prénom*",
      number: "Numéro de contact*",
      email: "E-mail professionnel*",
      organisation: "Organisation*",
      country: "Pays*",
      enquiry: "Publiez votre demande*",
      getQuote: "Obtenir un devis*",
    },
    italy: {
      code: "IT",
      requestQuote: "Richiedi un preventivo",
      name: "Nome e cognome*",
      number: "Contatta il n*",
      email: "E-mail di lavoro*",
      organisation: "Organizzazione*",
      country: "Paese*",
      enquiry: "Pubblica la tua richiesta*",
      getQuote: "Ottieni un preventivo*",
    },
    spanish: {
      code: "ES",
      requestQuote: "Solicitar una cotización*",
      name: "Nombre completo*",
      number: "Número de contacto*",
      email: "Correo electrónico del trabajo*",
      organisation: "Organización*",
      country: "País*",
      enquiry: "Publique su consulta*",
      getQuote: "Obtener cotización*",
    },
    dutch: {
      code: "NL",
      requestQuote: "Vraag een offerte aan",
      name: "Volledige naam*",
      number: "Contactnr*",
      email: "Werk e-mail*",
      organisation: "Organisatie*",
      country: "Land*",
      enquiry: "Plaats uw aanvraag*",
      getQuote: "Ontvang een offerte*",
    },
    german: {
      code: "DE",
      requestQuote: "Fordern Sie ein Angebot an",
      name: "Vollständiger Name*",
      number: "Kontakt Nr*",
      email: "Arbeits-E-Mail*",
      organisation: "Organisation*",
      country: "Land*",
      enquiry: "Veröffentlichen Sie Ihre Anfrage*",
      getQuote: "Holen Sie sich ein Angebot*",
    },
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const response = await fetch("/api/verify-captcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ capVal }),
    });

    const dataCaptcha = await response.json();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      customBikeData,
      enquiry,
    };
    if (dataCaptcha) {
      const { data } = await axios.post(
        "/api/form/request-a-quote",
        reqBody,
        config
      );
      if (data) {
        const modal = document.getElementById("exampleModal");
        if (modal) {
          modal.classList.remove("show");
          modal.setAttribute("aria-hidden", "true");
          // modal.style.display = "none";
        }

        // Manually remove the Bootstrap modal backdrop
        document.body.classList.remove("modal-open");
        document
          .querySelectorAll(".modal-backdrop")
          .forEach((el) => el.remove());
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
        navigate("/details-submitted");
      }
    } else {
      alert("CAPTCHA Verification Failed!");
    }
  };
  return (
    <>
      <button
        type="button"
        className={`fw-bold ${className}`}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {language ? translations[language].requestQuote : "Request a Quote"}
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content p-3 pt-2 rounded-4"
            style={{ backgroundColor: "#F9F9FF" }}
          >
            <div class="modal-header pt-2 mb-3">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-3">
              <form className="" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language ? translations[language].name : "Name*"}
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label for="exampleInputPassword1" class="form-label ">
                      {language ? translations[language].number : "Contact No*"}
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry={
                        language ? translations[language].code : "US"
                      }
                      className="form-control w-100"
                    />
                  </div>
                  <div className="w-50">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language ? translations[language].email : "Work Email*"}
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-100">
                    <label for="exampleInputEmail1" className="form-label ">
                      {language
                        ? translations[language].organisation
                        : "Organisation*"}
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label ">
                    {language
                      ? translations[language].enquiry
                      : "Post Your Enquiry*"}
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    required
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="mb-3 d-flex justify-content-center">
                  <ReCAPTCHA
                    sitekey="6LftKugqAAAAAP-72NIok7YyszCs5TTba5QBtgBS"
                    onChange={(val) => setCapVal(val)}
                  ></ReCAPTCHA>
                </div>
                <button
                  type="submit"
                  disabled={!capVal}
                  class="btn primary-button w-100 poppins-medium"
                >
                  {language ? translations[language].getQuote : "Get Quote"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
