import React, { useEffect } from "react";

const WhatsAppBot = ({ bottom }) => {
  return (
    <>
      <a
        href="https://bit.ly/42qpFZ6"
        className="wa-chat-btn-fixed-wrap"
        target="_blank"
      >
        <img
          className="wa-chat-btn-icon-image btn-whatsapp-pulse"
          alt="Whatsapp Chat Button"
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/whatsapp+logo.png"
        />
      </a>
    </>
  );
};

WhatsAppBot.defaultProps = {
  bottom: "5%",
};

export default WhatsAppBot;
