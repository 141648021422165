import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/contact.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const [capVal, setCapVal] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const response = await fetch("/api/verify-captcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ capVal }),
    });

    const dataCaptcha = await response.json();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (dataCaptcha) {
      const { data } = await axios.post(
        "/api/form/contact-us",
        {
          name,
          email,
          phoneNumber,
          companyName,
          enquiry,
        },
        config
      );
      if (data) {
        navigate("/details-submitted");
      }
    } else {
      alert("CAPTCHA Verification Failed!");
    }
  };

  return (
    <>
      <Meta
        title={"Contact Us | Dynem"}
        description={
          "Dynem is a leading E-bike manufacturer and wholesale supplier. Contact us to if you've any questions or if you're interested in our products. "
        }
        cannonicalTag={"https://www.dynem.in/contact-us"}
      />
      <Navbar />
      {/* main banner */}
      <div className="position-relative">
        <img
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/aboutUs/factoryDesktopBanner.jpg"
          alt="factory"
          className="d-block img-fluid"
        />
        <div className="overlay-1">
          <div className="position-absolute contact-us-banner-heading">
            <h1 className="banner-heading">CONTACT US</h1>
            {/* <br></br> */}
            <p className="banner-text text-white">
              Slide into our DMs because we’d love to hear from you.
            </p>
          </div>
        </div>

        {/* desktop form */}
        <div className="d-none d-lg-block position-absolute contact-us-form rounded-4">
          <form
            className=" d-flex justify-content-between align-content-center flex-column p-4"
            onSubmit={submit}
          >
            <h5 className="mb-3 text-center text-black fw-bold">
              Get In Touch
            </h5>
            <input
              type="text"
              className="form-control input-gifting mb-3 w-100"
              required
              value={name}
              autoComplete="off"
              style={{ background: "none" }}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name*"
            />
            <input
              type="email"
              placeholder="Email*"
              required
              className="form-control input-gifting mb-3 w-100"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PhoneInput
              placeholder="Phone Number*"
              required
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
              className="form-control mb-3 w-100"
            />
            <input
              type="text"
              placeholder="Company Name*"
              required
              className="form-control input-gifting mb-3 w-100"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <textarea
              class="form-control input-gifting mb-3 w-100"
              placeholder="Post your enquiry*"
              required
              id="exampleFormControlTextarea1"
              rows="3"
              value={enquiry}
              onChange={(e) => {
                setEnquiry(e.target.value);
              }}
            ></textarea>
            <div className="mb-3 d-flex justify-content-center">
              <ReCAPTCHA
                sitekey="6LftKugqAAAAAP-72NIok7YyszCs5TTba5QBtgBS"
                onChange={(val) => setCapVal(val)}
              ></ReCAPTCHA>
            </div>
            <button
              disabled={!capVal}
              type="submit"
              className=" w-100 contact-us-submit p-2 rounded-3"
            >
              Submit
            </button>
            <small
              className="text-center text-secondary mt-4"
              style={{ fontSize: "0.7em" }}
            >
              By choosing to confirm, you consent to share your personal
              information with Dynem. Terms and Conditions applied.
            </small>
          </form>
        </div>
      </div>
      {/* mobile form */}
      <div className="d-lg-none p-3">
        <div className="contact-us-form rounded-4">
          <form
            className=" d-flex justify-content-between align-content-center flex-column p-2"
            onSubmit={submit}
          >
            <h5 className="mb-3 text-center text-black fw-bold">
              Get In Touch
            </h5>
            <input
              type="text"
              className="form-control input-gifting mb-3 w-100"
              required
              value={name}
              autoComplete="off"
              style={{ background: "none" }}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name*"
            />
            <input
              type="email"
              placeholder="Email*"
              required
              className="form-control input-gifting mb-3 w-100"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PhoneInput
              placeholder="Phone Number*"
              required
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry="US"
              className="form-control mb-3 w-100"
            />
            <input
              type="text"
              placeholder="Company Name*"
              required
              className="form-control input-gifting mb-3 w-100"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />

            <textarea
              class="form-control input-gifting mb-3 w-100"
              placeholder="Post your enquiry*"
              required
              id="exampleFormControlTextarea1"
              rows="3"
              value={enquiry}
              onChange={(e) => {
                setEnquiry(e.target.value);
              }}
            ></textarea>
            <div className="mb-3 d-flex justify-content-center">
              <ReCAPTCHA
                sitekey="6LftKugqAAAAAP-72NIok7YyszCs5TTba5QBtgBS"
                onChange={(val) => setCapVal(val)}
              ></ReCAPTCHA>
            </div>
            <button
              disabled={!capVal}
              type="submit"
              className=" w-100 contact-us-submit p-2 rounded-3"
            >
              Submit
            </button>
            <small
              className="text-center text-secondary mt-4"
              style={{ fontSize: "0.7em" }}
            >
              By choosing to confirm, you consent to share your personal
              information with Dynem. Terms and Conditions applied.
            </small>
          </form>
        </div>
      </div>

      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default ContactUs;
